<template>
  <div class="kj_expert">
    <div class="kj_expert_bg" v-if="Object.keys(zjData).length != 0">
      <img :src="zjData.basicImg" alt="" style="width:100%" />
    </div>
    <ul class="kj_expert_nav">
      <li v-for="(item, idx) in zjList" :key="idx">
        <div class="li_img">
          <img :src="item.site_images" alt="" />
        </div>
        <div class="li_text">
          <h2>{{ item.title }}</h2>
          <p>{{ item.subtitle }}</p>
        </div>
      </li>
      <div class="all-pagination">
        <el-pagination
          prev-text="上一页"
          next-text="下一页"
          @current-change="handleCurrentChange"
          :current-page.sync="page"
          background
          :page-size="10"
          layout="prev, pager, next,total"
          :total="total"
          v-if="total > 9"
        >
        </el-pagination>
        <span class="no_meet_conditions" v-else>暂无更多数据</span>
      </div>
    </ul>
  </div>
</template>

<script>
import { getPageMsg, getJyCaseList } from '@/api/api'
export default {
  components: {},
  data () {
    return {
      zjData: {},
      zjList: [],
      total: 0,
      page: 1
    }
  },
  watch: {},
  methods: {
    getData () {
      let that = this
      let id = sessionStorage.getItem(that.changeData() + 'id')
      getPageMsg(id).then(res => {
        if (res.data.code == 1) {
          that.zjData = res.data.data.basic
        }
      })
      that.getList(1)
    },
    getList (i) {
      let project = sessionStorage.getItem(this.changeData() + 'project')
      getJyCaseList(i, 10, project).then(res => {
        if (res.data.code == 1) {
          this.zjList = res.data.data.list
          this.total = res.data.data.total
        }
      })
    },
    handleCurrentChange (val) {
      this.getList(val)
    }
  },
  mounted () {
    this.getData()
  }
}
</script>
<style lang="less" scoped>
@import url('~@/assets/css/mixins.less'); //混合
.kj_expert {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #f5f5f5;
  .kj_expert_bg {
    width: 100%;
    overflow: hidden;
    position: relative;
    img {
      animation: scaleAnim 3s ease forwards;
    }
  }
  .kj_expert_nav {
    margin: 1rem auto;
    width: 15rem;
    display: flex;
    flex-wrap: wrap;
    li {
      width: calc(50% - 20px);
      height: 260px;
      margin: 10px;
      display: flex;
      background-color: #fff;
      .li_img {
        width: 35%;
        height: 100%;
        flex-shrink: 0;
        img {
          max-width: 100%;
          height: 100%;
          margin: 0 auto;
          display: block;
        }
      }
      .li_text {
        flex-shrink: 0;
        width: calc(65% - 40px);
        height: calc(100% - 40px);
        padding: 20px;
        line-height: 1.5;
        h2 {
          color: #282828;
          font-size: 24px;
          font-weight: bold;
        }
        p {
          overflow: hidden auto;
          height: 78%;
          margin-top: 10px;
        }
      }
    }
  }
}
@keyframes scaleAnim {
  0% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
</style>
